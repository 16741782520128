import classnames from 'classnames';
import React from 'react';

import type { ReactNodeish } from '@/utils/types';

type Props = {
  background: boolean | ReactNodeish;
  headline: string;
  content: string;
  actions: ReactNodeish | ReactNodeish[];
  graphic: ReactNodeish | ReactNodeish[];
};

export default class HomepageAd extends React.PureComponent<Props> {
  static defaultProps = {
    background: true,
  };

  renderBackground(): ReactNodeish {
    const { background } = this.props;

    if (!background) {
      return null;
    }

    return typeof background === 'boolean' ? <BackgroundEllipse /> : background;
  }

  render(): ReactNodeish {
    const { headline, content, actions, graphic } = this.props;
    return (
      <div
        className={classnames({
          'homepage-announcement': true,
        })}>
        <div className="homepage-announcement__announcement-column">
          <div className="homepage-announcement__announcement">
            <div className="homepage-announcement__announcement__header">
              <h3 className="homepage-announcement__announcement__headline">{headline}</h3>
            </div>
            <div className="homepage-announcement__announcement__content">{content}</div>
            <div className="homepage-announcement__announcement__actions">{actions}</div>
          </div>
        </div>
        <div className="homepage-announcement__graphic-column">
          <div className="homepage-announcement__graphic">
            {this.renderBackground()}
            {graphic}
          </div>
        </div>
      </div>
    );
  }
}

const BackgroundEllipse = (): ReactNodeish => (
  <svg
    className="homepage-announcement__graphic__background__ellipse"
    viewBox="0 0 460 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true">
    <circle cx="476.5" cy="275.5" r="476.5" fill="#F5F6F7" />
  </svg>
);

export const BackgroundGradientEllipse = (): ReactNodeish => (
  <svg
    className="homepage-announcement__graphic__background__ellipse"
    viewBox="0 0 460 424"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true">
    <defs>
      <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#2b56cf" stopOpacity="1" />
        <stop offset="100%" stopColor="#1b4cae" stopOpacity="1" />
      </linearGradient>
    </defs>
    <circle cx="476.5" cy="275.5" r="476.5" fill="url(#grad1)" />
  </svg>
);
