import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import { Props as SampleQueryType } from '@/models/SampleQuery';
import ClickEvent from '@/analytics/models/ClickEvent';
import EnvInfo from '@/env/EnvInfo';
import EventTarget from '@/analytics/constants/EventTarget';
import IsDesktop from '@/components/util/env/IsDesktop';
import Link from '@/router/Link';
import QueryRoutes from '@/utils/routing/query-routes';
import trackAnalyticsEvent from '@/analytics/trackAnalyticsEvent';
import WeblabStore from '@/weblab/WeblabStore';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  queries: Immutable.List<SampleQueryType>;
  alignLeft?: boolean;
};

export default class SampleQueries extends React.PureComponent<Props> {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
    weblabStore: PropTypes.instanceOf(WeblabStore).isRequired,
  };

  trackSampleQueryClick({ query: queryText, queryType, field }: SampleQueryType): void {
    trackAnalyticsEvent(
      ClickEvent.create(EventTarget.EXAMPLE_QUERY, { queryText, queryType, field })
    );
  }

  render(): ReactNodeish {
    const { queries, alignLeft } = this.props;

    // Unfortunately the home page layout requires that the sample queries list exist, due to the
    // way it was styled. Accordingly if the query list is empty (which could mean it's loading),
    // we toggle a class to hide it (so that it still takes up space).
    const className = classnames('sample-queries', {
      'sample-queries--is-hidden': queries.isEmpty(),
      'sample-queries--align-left': !!alignLeft,
    });

    return (
      <div className={className}>
        <IsDesktop>
          <span className="sample-queries__label">{getString(_ => _.sampleQueries.try)}</span>
        </IsDesktop>
        <ul className="sample-queries__list">
          {queries.map(sampleQuery => {
            const { query, queryType, sort } = sampleQuery;
            return (
              <li key={query} className="sample-queries__list_item">
                <Link
                  onClick={() => {
                    this.trackSampleQueryClick(sampleQuery);
                  }}
                  aria-label={getString(_ => _.sampleQueries.queryAriaLabel, query)}
                  {...QueryRoutes.routeForQuery(
                    queryType === 'author' ? `"${query}"` : query,
                    null, // filterType
                    undefined, // routeName
                    sort
                  )}>
                  <>{query}</>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}
