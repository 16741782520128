import logger from '@/logger';

import PropTypes from 'prop-types';
import React from 'react';

export default class HeapContext extends React.PureComponent {
  static childContextTypes = {
    heapPropsChain: PropTypes.object.isRequired,
  };

  static contextTypes = {
    heapPropsChain: PropTypes.object,
  };

  getChildContext() {
    const { heapPropsChain: parentHeapProps = {} } = this.context;
    const { heapProps: selfHeapProps = {} } = this.props;
    const heapPropsChain = (() => {
      if (typeof selfHeapProps === 'function') {
        return selfHeapProps(parentHeapProps);
      } else {
        // $FlowFixMe: Next line causes error when flow typing was added to context (See: #22776)
        checkForOverrides(parentHeapProps, selfHeapProps);
        return {
          ...parentHeapProps,
          ...selfHeapProps,
        };
      }
    })();
    return { heapPropsChain };
  }

  render() {
    return this.props.children || null;
  }
}

let hasWarned = false;
export function checkForOverrides(parentProps, childProps) {
  if (hasWarned) {
    return; // Only warn once
  }
  const overwrittenKeys = getOverrideKeys(parentProps, childProps);
  if (overwrittenKeys.length > 0) {
    logger.warn(`<HeapTracking/> props overwritten (see: ${overwrittenKeys.join(', ')})`);
    hasWarned = true;
  }
}

export function getOverrideKeys(parentProps, childProps) {
  return Object.keys(parentProps)
    .filter(key => key in childProps) // Find overlapping keys
    .filter(key => parentProps[key] !== childProps[key]); // Find overwritten values
}
