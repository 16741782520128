import { mkHeapAttributes } from '@/analytics/heapUtils';

export const heapPublicApiAd = () =>
  mkHeapAttributes({
    id: 'homepage_public_api_ad',
  });

export const heapSemanticReaderAdDataset = () =>
  mkHeapAttributes({
    id: 'semantic_reader_ad_button',
  });
