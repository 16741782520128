import HomepageAdsSection from './HomepageAdsSection';
import SampleQueries from './SampleQueries';

import { getString } from '@/content/i18n';
import { ReactNodeish } from '@/utils/types';
import { S2HomePageLogo, S2LogoFull } from '@/components/shared/s2Logos';
import { Props as SampleQueryType } from '@/models/SampleQuery';
import AppHeader from '@/components/shared/layout/AppHeader';
import EnvInfo from '@/env/EnvInfo';
import IsDesktop from '@/components/util/env/IsDesktop';
import IsMobile from '@/components/util/env/IsMobile';
import PageSection from '@/components/shared/layout/PageSection';
import Searchbar from '@/components/shared/search/Searchbar';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  sampleQueries: Immutable.List<SampleQueryType>;
};

// We disable the no-autofocus rule because we want to allow focus on the homepage searchbar but
// nowhere else
/* eslint-disable jsx-a11y/no-autofocus */
export default class SearchHero extends React.PureComponent<Props> {
  static contextTypes = {
    envInfo: PropTypes.instanceOf(EnvInfo).isRequired,
  };

  render(): ReactNodeish {
    const { envInfo } = this.context;
    const isMobile = envInfo.isMobile;
    return (
      <PageSection className="hero">
        <AppHeader showSearchBar={false} />
        <div className="hero-content" role="main" id="main-content">
          <div className="container hero-container">
            <div className="s2-homepage-logo">
              <IsDesktop>
                <S2HomePageLogo />
              </IsDesktop>
              <IsMobile>
                <S2LogoFull />
              </IsMobile>
              <h2 className="hero-search__sublabel">{getString(_ => _.home.subHeadline)}</h2>
            </div>
            <Searchbar autoFocus />
            <SampleQueries queries={this.props.sampleQueries} />
          </div>
          <HomepageAdsSection isMobile={isMobile} />
        </div>
      </PageSection>
    );
  }
}
