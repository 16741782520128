import SearchHero from './SearchHero';

import { LOGIN_LOCATION } from '@/constants/LoginMethods';
import { mapAppContextToProps, useStateFromStore } from '@/AppContext';
import { ModalId } from '@/constants/Modal';
import { PAGE_TYPE_HOME } from '@/constants/HeapPageType';
import { ReactNodeish } from '@/utils/types';
import { Props as SampleQueryType } from '@/models/SampleQuery';
import { searchStringToObj } from '@/router/routerUtils';
import { showModal } from '@/actions/ModalActionCreators';
import AppFooter from '@/components/shared/layout/AppFooter';
import AuthStore from '@/stores/AuthStore';
import HeapContext from '@/components/util/HeapContext';
import Page from '@/components/shared/layout/Page';
import S2Dispatcher from '@/utils/S2Dispatcher';
import S2History from '@/utils/S2History';

import PropTypes from 'prop-types';
import React from 'react';

type Props = {
  sampleQueries: Immutable.List<SampleQueryType>;
};

export class HomePage extends React.Component<Props> {
  static contextTypes = {
    authStore: PropTypes.instanceOf(AuthStore).isRequired,
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
    history: PropTypes.instanceOf(S2History).isRequired,
  };

  componentDidMount() {
    const { authStore, history, dispatcher } = this.context;
    const query = searchStringToObj(history.location.search);

    if (query.signIn && !authStore.hasAuthenticatedUser()) {
      dispatcher.dispatch(showModal({ id: ModalId.SIGNUP, location: LOGIN_LOCATION.navBar }));
    }
  }

  render(): ReactNodeish {
    return (
      <HeapContext
        heapProps={{
          'page-type': PAGE_TYPE_HOME,
          'relevance-bucket': 'featured',
        }}>
        <Page
          className="home-page__search_emphasis"
          header={false}
          footer={<AppFooter theme="dark" />}>
          <SearchHero sampleQueries={this.props.sampleQueries} />
        </Page>
      </HeapContext>
    );
  }
}

export default mapAppContextToProps(HomePage, appContext => {
  const sampleQueriesStoreProps = useStateFromStore(appContext.sampleQueriesStore, _ => ({
    sampleQueries: _.getSampleQueries(),
  }));

  return {
    ...sampleQueriesStoreProps,
  };
});
